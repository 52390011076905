import React, { useEffect, useState } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import "../scss/header.scss";
import { useLogoutMutation } from "../services/auth";
import { useLocation } from "react-router-dom";

const AdminLayout = () => {
  const navigate = useNavigate();
  let location = useLocation();

  const [logout, { isError, isSuccess }] = useLogoutMutation();
  const refresh_token = localStorage.getItem("refresh_token") || "";

  useEffect(() => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    navigate("/admin/login");
  }, [isError, isSuccess]);

  const [isShowHeader, setIsShowHeader] = useState();

  return (
    <>
      {location.pathname !== "/admin/login" && (
        <header className="header">
          <div className="header__container">
            <div className="header__list">
              <Link to="/admin/main">Главная</Link>
              <Link to="/admin/group">Группы</Link>
              <Link to="/admin/role">Роли</Link>
              <Link to="/admin/user">Пользователи</Link>
              <Link to="/admin/token">Ключи устройств</Link>
            </div>
            <button
              className="btn"
              onClick={() => {
                logout({
                  refresh_token: refresh_token,
                });
              }}
            >
              Выйти
            </button>
          </div>
        </header>
      )}
      <div className="container">
        <Outlet />
      </div>
    </>
  );
};

export default AdminLayout;
