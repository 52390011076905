import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";

type AuthState = {
  access_token: string | null;
  refreshToken: string | null;
  isInitialized: boolean;
};

const initialState: AuthState = {
  access_token: null,
  refreshToken: null,
  isInitialized: false,
};

const slice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setCredentials: (
      state,
      { payload: { access_token, refreshToken } }: PayloadAction<{ access_token?: string | null; refreshToken?: string | null }>
    ) => {
      console.log("token: ", access_token);

      if (access_token && refreshToken) {
        state.access_token = access_token;
        localStorage.setItem("access_token", access_token);
        localStorage.setItem("refreshToken", refreshToken);
      }

      if (!state.isInitialized) {
        state.isInitialized = true;
      }
    },
    signOut: (state) => {
      
    },
  },
});

export const { setCredentials, signOut } = slice.actions;

export default slice.reducer;

export const selectIsAuth = (state: RootState) => state.auth.isInitialized;
export const getToken = (state: RootState) => state.auth.access_token;