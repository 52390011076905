import React, { useEffect, useState } from "react";
import "../scss/login.scss";
import { useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import axios from "axios";
import { config } from "../config";

const { baseUrl } = config;

type Inputs = {
  username: string;
  password: string;
};

function AdminLogin() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    login(data);
    console.log(data);
  };

  useEffect(() => {
    console.log(errors);
    setErrorLogin('');
  }, [errors.username, errors.password]);

  const [errorLogin, setErrorLogin] = useState<string>();

  const login = (data: Inputs) => {
    axios
      .post(`${baseUrl}/security/login-admin-panel`, data)
      .then((res) => res.data.data)
      .then((res) => {
        console.log(res);
        localStorage.setItem("access_token", res.access_token);
        localStorage.setItem("refresh_token", res.refresh_token);
        navigate("/admin/main");
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
        setErrorLogin(message);
      });
  };

  return (
    <div className="login">
      <div className="login__container">
        <h1>Вход</h1>
        <p>Заполните поля ниже для входа:</p>
        <form onSubmit={handleSubmit(onSubmit)} className="login__form">
          <div className={`form-field ${errors.username ? "invalid" : ""}`}>
            <div className="form-field__label">Логин</div>
            <input {...register("username", { required: true })} />
            {errors.username && (
              <div className="form-field__error">
                {errors.username.type === "required" &&
                  "Это поле обязательно для заполнения"}
              </div>
            )}
          </div>
          <div className={`form-field ${errors.password ? "invalid" : ""}`}>
            <div className="form-field__label">Пароль</div>
            <input {...register("password", { required: true })} />
            {errors.password && (
              <div className="form-field__error">
                {errors.password.type === "required" &&
                  "Это поле обязательно для заполнения"}
              </div>
            )}
          </div>
          {errorLogin && <div className="form-field__error">{errorLogin}</div>}
          <input type="submit" className="btn btn-block" value="Войти" />
        </form>
      </div>
    </div>
  );
}

export default AdminLogin;
