import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { LoginForm, LoginResponseSuccess } from "../types/auth";
import { config } from "../config";
import { setCredentials } from "../store/authSlice";

const { baseUrl } = config;

const baseQuery = fetchBaseQuery({
  baseUrl: `${baseUrl}`,
  prepareHeaders: (headers) => {
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      headers.set("authorization", `Bearer ${access_token}`);
    }
    headers.set("Content-Type", "application/json");

    return headers;
  },
});

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    login: builder.mutation<LoginResponseSuccess, LoginForm>({
      query: (body) => ({
        url: "security/login-admin-panel",
        method: "POST",
        body: body,
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const result = await queryFulfilled;

          if (result.data.status === true) {
            const { access_token } = result.data.data;
            dispatch(
              setCredentials({
                access_token: access_token,
              })
            );
          }
        } catch (error) {
          console.log("query error: ", error);
        }
      },
    }),
    logout: builder.mutation<undefined, {refresh_token: string}>({
      query: (body) => ({
        url: "security/logout",
        method: "POST",
        body: body,
      }),
      onQueryStarted() {
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
      },
    }),
  }),
});

export const { useLoginMutation, useLogoutMutation } = authApi;
