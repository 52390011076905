import React from 'react';
import './scss/App.scss';
import { Provider } from 'react-redux';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { store } from "./store/store";
import StartPage from './pages/StartPage';
import AdminLayout from './components/AdminLayout';
import AdminLogin from './pages/AdminLogin';
import AdminMain from './pages/AdminMain';
import Group from './pages/Group';
import Role from './pages/Role';
import Users from './pages/Users';
import CreateUser from './pages/CreateUser';
import UpdataUser from './pages/UpdataUser';
import TokenList from './pages/TokenList';
import CreateToken from './pages/CreateToken';
import UserLogin from './pages/UserLogin';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/admin" element={<AdminLayout />} >
            <Route path="main" element={<AdminMain />} />
            <Route path="login" element={<AdminLogin />} />
            <Route path="group" element={<Group />} />
            <Route path="role" element={<Role />} />
            <Route path="user" element={<Users />} />
            <Route path="user/create" element={<CreateUser />} />
            <Route path="user/update" element={<UpdataUser />} />
            <Route path="token" element={<TokenList />} />
            <Route path="token/create" element={<CreateToken />} />
            <Route path="*" element={<AdminMain />} />
          </Route>
          <Route path="/login" element={<UserLogin />} />
          <Route path="*" element={<StartPage />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
