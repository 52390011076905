import React, { useEffect, useState } from "react";
import { config } from "../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../scss/popup.scss";

type Token = {
  id: number;
  username: string;
  created_at: string;
  token: string;
};

const { baseUrl } = config;

function TokenList() {
  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");
  const [tokens, setTokens] = useState<Token[]>([]);
  const [selectedDeletToken, setSelectedDeletToken] = useState<Token | null>(
    null
  );
  const [selectedViewToken, setSelectedViewToken] = useState<Token | null>(
    null
  );

  const deleteToken = () => {
    axios
      .delete(`${baseUrl}/device-keys/${selectedDeletToken?.id}`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        console.log(response);
        setSelectedDeletToken(null);
        getTokens();
      })
      .catch((error) => {
        // const message = error.response.data.data.message;
        console.log(error);
      });
  };

  useEffect(() => {
    getTokens();
  }, []);

  const getTokens = () => {
    axios
      .get(`${baseUrl}/device-keys`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        setTokens(response.device_keys);
        console.log(response);
      })
      .catch((error) => {
        // const message = error.response.data.data.message;
        console.log(error);
      });
  };

  return (
    <>
      {selectedDeletToken && (
        <div
          className="popup"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setSelectedDeletToken(null);
            }
          }}
        >
          <div className="popup__container">
            <p className="center-text">
              Удалить ключ пользователя {selectedDeletToken.username}?
            </p>
            <div className="popup__container-row">
              <button
                className="btn btn-green"
                onClick={() => {
                  setSelectedDeletToken(null);
                }}
              >
                Отмена
              </button>
              <button
                className="btn btn-red"
                onClick={() => {
                  deleteToken();
                }}
              >
                Удалить
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedViewToken && (
        <div
          className="popup"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setSelectedViewToken(null);
            }
          }}
        >
          <div className="popup__container">
            <p className="popup__container-title">
              Ключ пользователя {selectedViewToken.username}:
            </p>
            <p className="popup__container-text">{selectedViewToken.token}</p>
          </div>
        </div>
      )}

      <h1 className="">Ключи устройств</h1>

      <div className="block">
        <button
          className="btn btn-green"
          onClick={() => {
            navigate("/admin/token/create");
          }}
        >
          Создать
        </button>
      </div>

      <div className="table">
        <div className="table__head">
          <div className="table__row">
            <div className="table__cell w-50">#</div>
            <div className="table__cell">ID</div>
            <div className="table__cell">Пользователь</div>
            <div className="table__cell">Создан</div>
            <div className="table__cell w-100"></div>
          </div>
        </div>
        <div className="table__body">
          {tokens.map((token, index) => (
            <div className="table__row" key={token.id}>
              <div className="table__cell w-50">{index + 1}</div>
              <div className="table__cell">{token.id}</div>
              <div className="table__cell">{token.username}</div>
              <div className="table__cell">
                {new Date(token.created_at).toLocaleString()}
              </div>

              <div className="table__cell table__cell-row w-100">
                <img
                  onClick={() => {
                    setSelectedViewToken(token);
                  }}
                  src="/icons/view.svg"
                  className="table__cell-icon"
                  alt=""
                />
                <img
                  onClick={() => {
                    setSelectedDeletToken(token);
                  }}
                  src="/icons/delete.svg"
                  className="table__cell-icon"
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default TokenList;
