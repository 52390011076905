import React, {useEffect, useState} from "react";
import "../scss/login.scss";
import { useNavigate} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import axios from "axios";
import {config} from "../config";

const { baseUrl } = config;

type Inputs = {
  username: string;
  password: string;
};

function UserLogin() {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    login(data);
    console.log(data);
  };

  useEffect(() => {
    console.log(errors);
    setErrorLogin("");
  }, [errors.username, errors.password]);

  const [errorLogin, setErrorLogin] = useState<string>();

  const login = (data: Inputs) => {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect_url');

    axios
      .post(`${baseUrl}/security/login-user`, {...data, redirect_url: redirectUrl})
      .then((res) => res.data.data)
      .then((res) => {
        console.log(res);
        window.location.href = `${res.redirect_url}?access_token=${res.access_token}&refresh_token=${res.refresh_token}`;
      })
      .catch((error) => {
        const data = error.response.data.data;

        if (error.status === 302) {
          window.location.href = `${data.redirect_url}?access_token=${data.access_token}&refresh_token=${data.refresh_token}`;
          return;
        }

        let messageError = '';

        if (data.message) {
          messageError = data.message;
        } else if (data.username) {
          messageError = data.username;
        } else if (data.password) {
          messageError = data.password;
        } else if (data.redirect_url) {
          messageError = data.redirect_url;
        }

        setErrorLogin(messageError);
      });
  };

  return (
    <div className="container">
      <div className="login">
        <div className="login__container">
          <h1>Микросервис единого входа</h1>
          <p>Заполните поля ниже для входа:</p>
          <form onSubmit={handleSubmit(onSubmit)} className="login__form">
            <div className={`form-field ${errors.username ? "invalid" : ""}`}>
              <div className="form-field__label">Логин</div>
              <input {...register("username", { required: true })} />
              {errors.username && (
                <div className="form-field__error">
                  {errors.username.type === "required" &&
                    "Это поле обязательно для заполнения"}
                </div>
              )}
            </div>
            <div className={`form-field ${errors.password ? "invalid" : ""}`}>
              <div className="form-field__label">Пароль</div>
              <input type="password" {...register("password", { required: true })} />
              {errors.password && (
                <div className="form-field__error">
                  {errors.password.type === "required" &&
                    "Это поле обязательно для заполнения"}
                </div>
              )}
            </div>
            {errorLogin && (
              <div className="form-field__error">{errorLogin}</div>
            )}
            <input type="submit" className="btn btn-block" value="Войти" />
          </form>
        </div>
      </div>
    </div>
  );
}

export default UserLogin;
