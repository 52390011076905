import React from 'react';
import { Outlet } from 'react-router-dom';

function AdminMain() {
    return (
        <>
            <h1 className='center-text'>Добро пожаловать в административную панель!</h1>
            <Outlet/>
        </>
    );
}

export default AdminMain;