import {combineReducers, configureStore, Reducer} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {setupListeners} from '@reduxjs/toolkit/query';
import { authApi } from '../services/auth';
import { groupApi } from '../services/group';
import authReducer from './authSlice';

// Объяединяем все редюсеры в одно хранилище(стор)
const combinedReducer = combineReducers({
  // Пример подключения reducer из rtk query 
  [authApi.reducerPath]: authApi.reducer,
  [groupApi.reducerPath]: groupApi.reducer,
  auth: authReducer,
});

export const resetStoreActionName = 'auth/signOut';

// Функция для сбрасывания всего стора(при логауте, итд)
const rootReducer: Reducer<RootState> = (state, action) => {
  // if (action.type === signOut.type) {
  //   state = {} as RootState;
  // }

  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => {
    const middlewares = getDefaultMiddleware()
      .concat(authApi.middleware)
      .concat(groupApi.middleware)

    return middlewares;
  },
  // Пример подключения промежуточных обработиков из rtk query
  // .concat(authApi.middleware)
});

setupListeners(store.dispatch);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
