import React, { useEffect, useState } from "react";
import { GroupType, RoleType } from "../types/auth";
import Tree from "rc-tree";
import "rc-tree/assets/index.css";
import "../scss/tree.scss";
import { config } from "../config";
import axios from "axios";

const { baseUrl } = config;

function Role() {
  const access_token = localStorage.getItem("access_token");
  const [roles, setRoles] = useState<any[]>();
  const [selectedRole, setSelectedRole] = useState<{key: number; tille: string;} | null>(null);
  const [selelectedRoleName, setSelelectedRoleName] = useState<string>("");

  const onSelect = (selectedKeys: any, info: any) => {
    console.log("selected", selectedKeys, info);
    setSelectedRole(selectedKeys[0] && info.node);
    setSelelectedRoleName(selectedKeys[0] ? info.node.title : "");
  };

  const convertData = (data: (RoleType | GroupType)[]): any => {
    return data.map((item) => ({
      key: item.guid,
      title: item.name,
      children: convertData(item.children),
    }));
  };

  const editRole = ({
    guid,
    name,
    parent_guid,
  }: {
    guid: number;
    name?: string;
    parent_guid?: string;
  }) => {
    const body: {
      name?: string;
      parent_guid?: string;
    } = {};

    name && (body.name = name);
    parent_guid && (body.parent_guid = parent_guid);

    axios
      .put(`${baseUrl}/roles/${guid}`, body, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        getRoles();
      })
      .catch((error) => {
        // const message = error.response.data.data.message;
        console.log(error);
      });
  };

  const creatRole = (roleId?: any) => {
    const body = {
      name: "Новая роль",
    };

    axios
      .post(`${baseUrl}/roles`, body, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        if (roleId) {
          console.log("creatGroup response: ", response);

          editRole({
            guid: response.role.guid,
            parent_guid: roleId.key,
          });
        } else {
          getRoles();
        }
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
      });
  };

  const deleteRole = (role: any) => {
    console.log("role: ", role);

    axios
      .delete(`${baseUrl}/roles/${role.key}`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        getRoles();
        console.log("deleteRole: ", response);
        setSelectedRole(null);
        setSelelectedRoleName("");
      })
      .catch((error) => {
        const message = error.response.data.data.message;
        console.log(message);
      });
  };

  const getRoles = () => {
    axios
      .get(`${baseUrl}/roles/hierarchy`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        const data = convertData(response.roles);
        setRoles(data);
        console.log(response);
      })
      .catch((error) => {
        // const message = error.response.data.data.message;
        console.log(error);
      });
  };

  useEffect(() => {
    getRoles();
  }, []);

  return (
    <>
      <h1 className="">Роли</h1>

      <div className="tree-bg">
        <div className="tree__buttons">
          <button
            className="btn btn-blue"
            onClick={() => {
              creatRole();
            }}
          >
            Создать корневую роль
          </button>

          {selectedRole && (
            <>
              <button
                className="btn btn-blue"
                onClick={() => {
                  creatRole(selectedRole);
                }}
              >
                Создать роль
              </button>

              <button
                className="btn btn-blue"
                onClick={() => {
                  deleteRole(selectedRole);
                }}
              >
                Удалить
              </button>

              <div className="tree__buttons-row">
                <div className={`form-field`}>
                  <input value={selelectedRoleName} onChange={(e) => {
                    setSelelectedRoleName(e.target.value);
                  }}/>
                </div>
                <button
                  className="btn btn-blue"
                  onClick={() => {
                    editRole({guid: selectedRole.key, name: selelectedRoleName})
                  }}
                >
                  Переименовать
                </button>
              </div>
            </>
          )}
        </div>

        <div className="tree__container">
          {roles?.length ? (
            <Tree
              treeData={roles}
              checkable
              onSelect={onSelect}
              defaultExpandAll
              showIcon
            />
          ) : (
            <p>нет данных</p>
          )}
        </div>
      </div>
    </>
  );
}

export default Role;
