import React, { useEffect, useState } from "react";
import { config } from "../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import "../scss/popup.scss";

export type User = {
  guid: string;
  username: string;
  role: string;
  status: string;
  created_at: string;
  updated_at: string;
  is_set_password: string;
  groups: number[];
  roles: number[];
};

const { baseUrl } = config;

function Users() {
  const navigate = useNavigate();

  const access_token = localStorage.getItem("access_token");
  const [users, setUsers] = useState<User[]>([]);
  const [selectedDeletUser, setSelectedDeletUser] = useState<User | null>(null);

  const deleteUser = () => {
    axios
    .delete(`${baseUrl}/users/${selectedDeletUser?.guid}`, {
      headers: {
        Authorization: "Bearer " + access_token,
      },
    })
    .then((response) => response.data.data)
    .then((response) => {
      console.log(response);
      setSelectedDeletUser(null);
      getUsers();
    })
    .catch((error) => {
      // const message = error.response.data.data.message;
      console.log(error);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    axios
      .get(`${baseUrl}/users`, {
        headers: {
          Authorization: "Bearer " + access_token,
        },
      })
      .then((response) => response.data.data)
      .then((response) => {
        setUsers(response.users);
        console.log(response);
      })
      .catch((error) => {
        // const message = error.response.data.data.message;
        console.log(error);
      });
  };

  return (
    <>
      {selectedDeletUser && (
        <div
          className="popup"
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setSelectedDeletUser(null);
            }
          }}
        >
          <div className="popup__container">
            <p className="center-text">
              Удалить пользователя {selectedDeletUser.username}?
            </p>
            <div className="popup__container-row">
              <button className="btn btn-green" onClick={() => {
                setSelectedDeletUser(null);
              }}>
                Отмена
              </button>
              <button className="btn btn-red" onClick={() => {
                deleteUser();
              }}>
                Удалить
              </button>
            </div>
          </div>
        </div>
      )}

      <h1 className="">Пользователи</h1>

      <div className="block">
        <button
          className="btn btn-green"
          onClick={() => {
            navigate("/admin/user/create");
          }}
        >
          Создать
        </button>
      </div>

      <div className="table">
        <div className="table__head">
          <div className="table__row">
            <div className="table__cell w-50">#</div>
            <div className="table__cell">GUID</div>
            <div className="table__cell">Имя пользователя</div>
            <div className="table__cell">Статус</div>
            <div className="table__cell">Создан</div>
            <div className="table__cell">Изменен</div>
            <div className="table__cell w-100"></div>
          </div>
        </div>
        <div className="table__body">
          {users.map((user, index) => (
            <div className="table__row" key={user.guid}>
              <div className="table__cell w-50">{index + 1}</div>
              <div className="table__cell">{user.guid}</div>
              <div className="table__cell">{user.username}</div>
              <div className="table__cell">{user.status}</div>
              <div className="table__cell">
                {new Date(user.created_at).toLocaleString()}
              </div>
              <div className="table__cell">
                {new Date(user.updated_at).toLocaleString()}
              </div>
              <div className="table__cell table__cell-row w-100">
                {/* <img
                  onClick={() => {}}
                  src="/icons/view.svg"
                  className="table__cell-icon"
                  alt=""
                /> */}
                <img
                  onClick={() => {
                    navigate("/admin/user/update", {
                      state: {
                        user,
                      },
                    });
                  }}
                  src="/icons/edit.svg"
                  className="table__cell-icon"
                  alt=""
                />
                <img
                  onClick={() => {
                    setSelectedDeletUser(user);
                  }}
                  src="/icons/delete.svg"
                  className="table__cell-icon"
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Users;
